import React from 'react'
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout";
import Slider from "react-slick";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

class ExcelSync extends React.Component {
    constructor(props) {
        super(props);
        this.visualizationYourDataeProducts = this.visualizationYourDataeProducts.bind(this);
        this.manipulateDataSharePointAndSharePointOnline = this.manipulateDataSharePointAndSharePointOnline.bind(this);
        this.controlMultipleSitesProjects = this.controlMultipleSitesProjects.bind(this);
        this.workingOfflineVerifyIntegritySyncSiteStayConnected = this.workingOfflineVerifyIntegritySyncSiteStayConnected.bind(this);
        this.analyseManageDataExcelFunctions = this.analyseManageDataExcelFunctions.bind(this);
        this.hitButtonLiveDemoExcelSync = this.hitButtonLiveDemoExcelSync.bind(this);
        this.hitButtonPricingExcelSync = this.hitButtonPricingExcelSync.bind(this);
    }

    // componentDidMount() {
    //     const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-section-excel-sync-try-product-free .app-excel-sync-try-product-free-detail .app-excel-sync-try-product-free-faqs-onlinedoc a.app-excel-sync-try-product-free-faqs-onlinedoc-a-tag").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    // }

	visualizationYourDataeProducts() {
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-left').classList.toggle('visualization-data-eProducts-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-right').classList.toggle('visualization-data-eProducts-right-active');

        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-left').classList.remove('manipulate-data-sharepoint-sharepointonline-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-right').classList.remove('manipulate-data-sharepoint-sharepointonline-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-left').classList.remove('control-multiple-sites-projects-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-right').classList.remove('control-multiple-sites-projects-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-left').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-right').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-left').classList.remove('analyse-manage-data-excel-functions-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-right').classList.remove('analyse-manage-data-excel-functions-right-active');
    }

    manipulateDataSharePointAndSharePointOnline() {
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-left').classList.toggle('manipulate-data-sharepoint-sharepointonline-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-right').classList.toggle('manipulate-data-sharepoint-sharepointonline-right-active');

        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-left').classList.remove('visualization-data-eProducts-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-right').classList.remove('visualization-data-eProducts-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-left').classList.remove('control-multiple-sites-projects-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-right').classList.remove('control-multiple-sites-projects-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-left').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-right').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-left').classList.remove('analyse-manage-data-excel-functions-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-right').classList.remove('analyse-manage-data-excel-functions-right-active');
    }

    controlMultipleSitesProjects() {
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-left').classList.toggle('control-multiple-sites-projects-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-right').classList.toggle('control-multiple-sites-projects-right-active');

        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-left').classList.remove('visualization-data-eProducts-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-right').classList.remove('visualization-data-eProducts-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-left').classList.remove('manipulate-data-sharepoint-sharepointonline-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-right').classList.remove('manipulate-data-sharepoint-sharepointonline-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-left').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-right').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-left').classList.remove('analyse-manage-data-excel-functions-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-right').classList.remove('analyse-manage-data-excel-functions-right-active');
    }

    workingOfflineVerifyIntegritySyncSiteStayConnected() {
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-left').classList.toggle('working-offline-verify-integrity-sync-site-stay-connected-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-right').classList.toggle('working-offline-verify-integrity-sync-site-stay-connected-right-active');

        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-left').classList.remove('visualization-data-eProducts-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-right').classList.remove('visualization-data-eProducts-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-left').classList.remove('manipulate-data-sharepoint-sharepointonline-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-right').classList.remove('manipulate-data-sharepoint-sharepointonline-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-left').classList.remove('control-multiple-sites-projects-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-right').classList.remove('control-multiple-sites-projects-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-left').classList.remove('analyse-manage-data-excel-functions-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-right').classList.remove('analyse-manage-data-excel-functions-right-active');
    }

    analyseManageDataExcelFunctions() {
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-left').classList.toggle('analyse-manage-data-excel-functions-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .analyse-manage-data-excel-functions .analyse-manage-data-excel-functions-right').classList.toggle('analyse-manage-data-excel-functions-right-active');

        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-left').classList.remove('visualization-data-eProducts-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .visualization-data-eProducts .visualization-data-eProducts-right').classList.remove('visualization-data-eProducts-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-left').classList.remove('manipulate-data-sharepoint-sharepointonline-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .manipulate-data-sharepoint-sharepointonline .manipulate-data-sharepoint-sharepointonline-right').classList.remove('manipulate-data-sharepoint-sharepointonline-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-left').classList.remove('control-multiple-sites-projects-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .control-multiple-sites-projects .control-multiple-sites-projects-right').classList.remove('control-multiple-sites-projects-right-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-left').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-left-active');
        document.querySelector('.app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel .working-offline-verify-integrity-sync-site-stay-connected .working-offline-verify-integrity-sync-site-stay-connected-right').classList.remove('working-offline-verify-integrity-sync-site-stay-connected-right-active');
    }

    hitButtonLiveDemoExcelSync() {
        var data = {};
        data["type"] = "click";
        data["button"] = "liveDemoExcelSync";
        
        var checkItemLocalStorage = localStorage.getItem("dataLiveDemoExcelSync");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataLiveDemoExcelSync", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    hitButtonPricingExcelSync() {
        var data = {};
        data["type"] = "click";
        data["button"] = "pricingExcelSync";
        
        var checkItemLocalStorage = localStorage.getItem("dataPricingExcelSync");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataPricingExcelSync", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    render() {
        const { data } = this.props;
        const introduction = data.allMarkdownRemark.nodes;
        const visualization_data_eProducts = data.visualization_data_eProducts.nodes;
        const manipulate_data_sharepoint_sharepointonline = data.manipulate_data_sharepoint_sharepointonline.nodes;
        const control_multiple_sites_projects = data.control_multiple_sites_projects.nodes;
        const working_offline_verify_integrity_sync_site_stay_connected = data.working_offline_verify_integrity_sync_site_stay_connected.nodes;
        const analyse_manage_data_excel_functions = data.analyse_manage_data_excel_functions.nodes;
        const capabilities_excel_sync_eProducts_introduction = data.capabilities_excel_sync_eProducts_introduction.nodes;
        const capabilities_excel_sync_eProducts_detail = data.capabilities_excel_sync_eProducts_detail.nodes;
        const specifications_etask_team_app = data.specifications_etask_team_app.nodes;
        const excel_sync_try_product_free_live_demo = data.excel_sync_try_product_free_live_demo.nodes;
        const excel_sync_try_product_free_faqs_onlinedoc = data.excel_sync_try_product_free_faqs_onlinedoc.nodes;
        const excel_sync_explore_now = data.excel_sync_explore_now.nodes;

        var customSettingsCapabilitiesExcelSynceProducts = {
            dots: false,
            speed: 500,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1367,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1279,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Layout>
                <SEO title="eXsync" description="" meta={[{ name: `keywords`, content: ["", "", "", ""] }]} pathname="/products/excel-sync" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-excel-sync-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-excel-sync-introduction-detail">
                                <Row className="app-excel-sync-introduction-row-custom">
                                    <Col className="app-excel-sync-introduction-col-custom" xs={12} md={12} lg={5}>
                                        <div className="app-excel-sync-introduction-left">
                                            {introduction.map((res) => (
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={1}></Col>
                                    <Col className="app-excel-sync-introduction-col-custom" xs={12} md={12} lg={6}>
                                        <div className="app-excel-sync-introduction-right">
                                            <img className="app-excel-sync-introduction-img" src="/app-excel-sync-introduction.svg" alt="Excel Sync introduction" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-title">
                                        <h2>Managing data of eProducts and SharePoint via Microsoft Excel</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail">
                                <Row className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-row-custom visualization-data-eProducts">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom visualization-data-eProducts-left" xs={12} md={12} lg={12} xl={5}>
                                        {visualization_data_eProducts.map((res) => (
                                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom visualization-data-eProducts-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-right-side">
                                            <img 
                                                className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-visualization-data-eProducts" 
                                                src="/app-excel-sync-01.svg" 
                                                alt="app excel sync managing data eProducts sharepoint via microsoft excel visualization data eProducts" 
                                                onClick={this.visualizationYourDataeProducts} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-row-custom manipulate-data-sharepoint-sharepointonline">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom manipulate-data-sharepoint-sharepointonline-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-left-side">
                                            <img 
                                                className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-manipulate-data-sharepoint-sharepointonline" 
                                                src="/app-excel-sync-02.svg" 
                                                alt="app excel sync managing data eProducts sharepoint via microsoft excel manipulate data sharepoint sharepointonline" 
                                                onClick={this.manipulateDataSharePointAndSharePointOnline} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom manipulate-data-sharepoint-sharepointonline-right" xs={12} md={12} lg={12} xl={5}>
                                        {manipulate_data_sharepoint_sharepointonline.map((res) => (
                                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-row-custom control-multiple-sites-projects">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom control-multiple-sites-projects-left" xs={12} md={12} lg={12} xl={5}>
                                        {control_multiple_sites_projects.map((res) => (
                                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom control-multiple-sites-projects-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-right-side">
                                            <img 
                                                className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-control-multiple-sites-projects" 
                                                src="/app-excel-sync-03.svg" 
                                                alt="app excel sync managing data eProducts sharepoint via microsoft excel control multiple sites projects" 
                                                onClick={this.controlMultipleSitesProjects} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={2} xl={1}></Col>
                                </Row>
                                <Row className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-row-custom working-offline-verify-integrity-sync-site-stay-connected">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom working-offline-verify-integrity-sync-site-stay-connected-left" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-left-side">
                                            <img 
                                                className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-working-offline-verify-integrity-sync-site-stay-connected" 
                                                src="/app-excel-sync-04.svg" 
                                                alt="app excel sync managing data eProducts sharepoint via microsoft excel working offline verify integrity sync site stay connected" 
                                                onClick={this.workingOfflineVerifyIntegritySyncSiteStayConnected} 
                                            />
                                        </div>
                                    </Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom working-offline-verify-integrity-sync-site-stay-connected-right" xs={12} md={12} lg={12} xl={5}>
                                        {working_offline_verify_integrity_sync_site_stay_connected.map((res) => (
                                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                                <Row className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-row-custom analyse-manage-data-excel-functions">
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom analyse-manage-data-excel-functions-left" xs={12} md={12} lg={12} xl={5}>
                                        {analyse_manage_data_excel_functions.map((res) => (
                                            <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </Col>
                                    <Col className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-detail-col-custom analyse-manage-data-excel-functions-right" xs={12} md={12} lg={12} xl={5}>
                                        <div className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-right-side">
                                            <img 
                                                className="app-excel-sync-managing-data-eProducts-sharepoint-via-microsoft-excel-analyse-manage-data-excel-functions" 
                                                src="/app-excel-sync-05.svg" 
                                                alt="app excel sync managing data eProducts sharepoint via microsoft excel analyse manage data excel functions" 
                                                onClick={this.analyseManageDataExcelFunctions} 
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={1}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-excel-sync-capabilities-excel-sync-eProducts" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    {capabilities_excel_sync_eProducts_introduction.map((res) => (
                                        <div className="app-title" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-excel-sync-capabilities-excel-sync-eProducts-detail">
                                <Slider {...customSettingsCapabilitiesExcelSynceProducts}>
                                    {capabilities_excel_sync_eProducts_detail.map((res) => (
                                        <div className="app-capabilities-excel-sync-eProducts-slider" key={res.id}>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            <div className="content">
                                                <h5>{res.frontmatter.title}</h5>
                                                <h6>{res.frontmatter.description}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-section-excel-sync-specifications" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Specifications</h2>
                            </div>
                            <div className="app-excel-sync-specifications-detail">
                                <div className="app-excel-sync-specifications-detail-header">
                                    <h6>System Specifications for eXsync Team app</h6>
                                </div>
                                {specifications_etask_team_app.map((res) => (
                                    <Row className="app-excel-sync-specifications-detail-row-custom" key={res.id}>
                                        <Col className="app-excel-sync-specifications-detail-col-custom-child" xs={3} md={3} lg={1}>
                                            <h6>{res.frontmatter.title}</h6>
                                        </Col>
                                        <Col className="app-excel-sync-specifications-detail-col-custom-child" xs={9} md={9} lg={11}>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Container>
                    </div> */}
                    {/* <div className="app-general-seciton app-section-excel-sync-try-product-free" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-excel-sync-try-product-free-detail">
                                <Row>
                                    {excel_sync_try_product_free_live_demo.map((res) => (
                                        <Col xs={12} md={12} lg={4} key={res.id}>
                                            <div className="app-excel-sync-try-product-free-live-demo">
                                                <h4>{res.frontmatter.title}</h4>
                                                <h6><Link to="/products/demosite" onClick={this.hitButtonLiveDemoExcelSync}>Try It Now !</Link></h6>
                                            </div>
                                        </Col>
                                    ))}
                                    {excel_sync_try_product_free_faqs_onlinedoc.map((res) => (
                                        <Col xs={12} md={12} lg={4} key={res.id}>
                                            <div className="app-excel-sync-try-product-free-faqs-onlinedoc">
                                                <h4>{res.frontmatter.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div> */}
                    {/* <div className="app-section-excel-sync-explore-now" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        {excel_sync_explore_now.map((res) => (
                            <div key={res.id}>
                                <div className="app-title">
                                    <h4>{res.frontmatter.title}</h4>
                                </div>
                                <div className="app-section-excel-sync-explore-now-detail">
                                    <h6><Link to="/pricing" onClick={this.hitButtonPricingExcelSync}>Buy It Now</Link></h6>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default ExcelSync;

export const query = graphql`
    query ExcelSyncPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		visualization_data_eProducts: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_visualization_data_eProducts" } } }
        ) {
            nodes {
                html
                id
            }
        }
        manipulate_data_sharepoint_sharepointonline: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_manipulate_data_sharepoint_sharepointonline" } } }
        ) {
            nodes {
                html
                id
            }
        }
        control_multiple_sites_projects: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_control_multiple_sites_projects" } } }
        ) {
            nodes {
                html
                id
            }
        }
        working_offline_verify_integrity_sync_site_stay_connected: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_working_offline_verify_integrity_sync_site_stay_connected" } } }
        ) {
            nodes {
                html
                id
            }
        }
        analyse_manage_data_excel_functions: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_analyse_manage_data_excel_functions" } } }
        ) {
            nodes {
                html
                id
            }
        }
        capabilities_excel_sync_eProducts_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_capabilities_excel_sync_eProducts_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        capabilities_excel_sync_eProducts_detail: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_capabilities_excel_sync_eProducts_detail" } } }
            sort: { fields: frontmatter___publishedAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        specifications_etask_team_app: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_specifications_etask_team_app" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        excel_sync_try_product_free_live_demo: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_try_product_free_live_demo" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        excel_sync_try_product_free_faqs_onlinedoc: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_try_product_free_faqs_onlinedoc" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        excel_sync_explore_now: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "excel_sync_explore_now" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;
